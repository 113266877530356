<template>
  <!-- tharuni Srtart -->
  <div>
   
     
        <el-row>
        <el-input
          class="search mb-1"
          placeholder="Search by icon name"
          v-model="searchIcon"
          clearable
          @input="searchIconName($event)"
        ></el-input>
      </el-row>
      <div >

          <div class="paragraphScroll">
            <div v-if="searchIcon && searchIcon.length"  class="icon-sty">
              <div v-for="(e, index) in iconsList" :key="index" @click="handleIconClick(e.icon)" >
                <div style="width: 100% !important" class="ml-1 mr-1">
                  <i :class="e.icon"> </i>
                  <p style="font-size: 10px;">{{ e.name}}</p>
                </div>
              </div>
            </div>  
            <div class="icon-sty" v-else>
              <div v-for="(e, index) in iconsArray" :key="index" @click="handleIconClick(e.icon)" >
                <div style="width: 100% !important" class="ml-1 mr-1">
                  <i :class="e.icon"> </i>
                  <p style="font-size: 10px;">{{ e.name}}</p>
              <!-- <p style="font-size: 10px;word-wrap: keep-all;">{{ e.name}}</p> -->
              </div>
            </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { bus } from "../../main";
export default {

  data() {
    return {
      iconsList:[],
      searchIcon: "",
      iconsArray: [
        {
          icon: "el-icon-platform-eleme",
          name:'eleme'
        },
        {
          icon: "el-icon-eleme",
          name:'eleme1'
        },
        {
          icon: "el-icon-delete-solid",
          name:'delete'
        },
        {
          icon: "el-icon-delete",
          name:'delete1'
        },
        {
          icon: "el-icon-s-tools",
          name:'tools'
        },
        {
          icon: "el-icon-setting",
          name:'setting'
        },
        {
          icon: "el-icon-user-solid",
          name:'user'
        },
        {
          icon: "el-icon-user",
          name:'user1'
        },
        {
          icon: "el-icon-phone",
          name:'phone'
        },
        {
          icon:  "el-icon-phone-outline",
          name:'phone1'
        },
        {
          icon:  "el-icon-more",
          name:'more'
        },
        {
          icon: "el-icon-more-outline",
          name:'more1'
        },
        {
          icon: "el-icon-star-on",
          name:'staron'
        },
        {
          icon:  "el-icon-star-off",
          name:'staroff'
        },
        {
          icon:   "el-icon-s-goods",
          name:'goods'
        },
        {
          icon: "el-icon-goods",
          name:'goods1'
        },
        {
          icon:  "el-icon-warning",
          name:'warning'
        },
        {
          icon: "el-icon-warning-outline",
          name:'warning1'
        },
        {
          icon: "el-icon-question",
          name:'question'
        },
        {
          icon:  "el-icon-info",
          name:'info'
        },
        {
          icon:  "el-icon-remove",
          name:'remove'
        },
        {
          icon: "el-icon-circle-plus",
          name:'cplus'
        },
        {
          icon: "el-icon-success",
          name:'success'
        },
        {
          icon: "el-icon-error",
          name:'error'
        },
        {
          icon: "el-icon-zoom-in",
          name:'zoomin'
        },
        {
          icon: "el-icon-zoom-out",
          name:'zoomout'
        },
        {
          icon: "el-icon-remove-outline",
          name:'remove1'
        },
        {
          icon:   "el-icon-circle-plus-outline",
          name:'plus2'
        },
        {
          icon:   "el-icon-circle-check",
          name:'check1'
        },
        {
          icon:   "el-icon-circle-close",
          name:'close'
        },
        {
          icon:    "el-icon-s-help",
          name:'help'
        },
        {
          icon:  "el-icon-help",
          name:'help2'
        },
        {
          icon:   "el-icon-minus",
          name:'minus'
        },
        {
          icon:  "el-icon-plus",
          name:'plus'
        },
        {
          icon:  "el-icon-check",
          name:'check2'
        },
        {
          icon:  "el-icon-close",
          name:'close'
        },
        {
          icon: "el-icon-picture",
          name:'picture'
        },
        {
          icon: "el-icon-picture-outline",
          name:'picture1'
        },
        {
          icon: "el-icon-picture-outline-round",
          name:'picture2'
        },
        {
          icon: "el-icon-upload",
          name:'upload'
        },
        {
          icon: "el-icon-upload2",
          name:'upload2'
        },
        {
          icon:  "el-icon-download",
          name:'download'
        },
        {
          icon: "el-icon-camera-solid",
          name:'camera'
        },
        {
          icon:  "el-icon-camera",
          name:'camera2'
        },
        {
          icon:  "el-icon-video-camera-solid",
          name:'vcamera1'
        },
        {
          icon:  "el-icon-video-camera",
          name:'vcamera2'
        },
        {
          icon:  "el-icon-message-solid",
          name:'message'
        },
        {
          icon: "el-icon-bell",
          name:'bell'
        },
        {
          icon: "el-icon-s-cooperation",
          name:'cooperation'
        },
        {
          icon: "el-icon-s-order",
          name:'order'
        },
        {
          icon: "el-icon-s-platform",
          name:'platform'
        },
        {
          icon: "el-icon-s-fold",
          name:'fold'
        },
        {
          icon: "el-icon-s-unfold",
          name:'unfold'
        },
        {
          icon:  "el-icon-s-operation",
          name:'operation'
        },
        {
          icon: "el-icon-s-promotion",
          name:'promotion'
        },
        {
          icon: "el-icon-s-home",
          name:'home'
        },
        {
          icon:   "el-icon-s-release",
          name:'release'
        },
        {
          icon:  "el-icon-s-ticket",
          name:'ticket'
        },
        {
          icon:  "el-icon-s-management",
          name:'management'
        },
        {
          icon:  "el-icon-s-open",
          name:'open'
        },
        {
          icon:  "el-icon-s-shop",
          name:'shop'
        },
        {
          icon:  "el-icon-s-marketing",
          name:'marketing'
        },
        {
          icon:  "el-icon-s-flag",
          name:'flag'
        },
        {
          icon:  "el-icon-s-comment",
          name:'comment'
        },
        {
          icon:   "el-icon-s-finance",
          name:'finance'
        },
        {
          icon:  "el-icon-s-claim",
          name:'claim'
        },
        {
          icon: "el-icon-s-custom",
          name:'custom'
        },
        {
          icon: "el-icon-s-opportunity",
          name:'opportunity'
        },
        {
          icon: "el-icon-s-data",
          name:'data'
        },
        {
          icon: "el-icon-s-check",
          name:'check'
        },
        {
          icon: "el-icon-s-grid",
          name:'grid'
        },
        {
          icon:   "el-icon-menu",
          name:'menu'
        },
        {
          icon:    "el-icon-share",
          name:'share'
        },
        {
          icon:    "el-icon-d-caret",
          name:'caret'
        },
        {
          icon:   "el-icon-caret-left",
          name:'caretl'
        },
        {
          icon:   "el-icon-caret-right",
          name:'caret2'
        },
        {
          icon:   "el-icon-caret-bottom",
          name:'caret3'
        },
        {
          icon:   "el-icon-caret-top",
          name:'caret4'
        },
        {
          icon:   "el-icon-bottom-left",
          name:'bottom'
        },
        {
          icon:   "el-icon-bottom-right",
          name:'caret5'
        },
        {
          icon:    "el-icon-back",
          name:'back'
        },
        {
          icon:    "el-icon-right",
          name:'right'
        },
        {
          icon:  "el-icon-bottom",
          name:'bottom'
        },
        {
          icon: "el-icon-top",
          name:'top'
        },
        {
          icon: "el-icon-top-left",
          name:'topl'
        },
        {
          icon: "el-icon-top-right",
          name:'top2'
        },
        {
          icon: "el-icon-arrow-left",
          name:'arrowl'
        },
        {
          icon:  "el-icon-arrow-right",
          name:'arrow2'
        },
        {
          icon: "el-icon-arrow-down",
          name:'arrow3'
        },
        {
          icon: "el-icon-arrow-up",
          name:'arrow4'
        },
        {
          icon: "el-icon-d-arrow-left",
          name:'arrow5'
        },
        {
          icon: "el-icon-d-arrow-right",
          name:'arrow6'
        },
        {
          icon: "el-icon-video-pause",
          name:'vpause'
        },
        {
          icon: "el-icon-video-play",
          name:'vplay'
        },
        {
          icon:"el-icon-refresh",
          name:'refresh'
        },
        {
          icon:"el-icon-refresh-right",
          name:'refresh1'
        },
        {
          icon:"el-icon-refresh-left",
          name:'refresh2'
        },
        {
          icon: "el-icon-finished",
          name:'finished'
        },
        {
          icon: "el-icon-sort",
          name:'sort'
        },
        {
          icon: "el-icon-sort-up",
          name:'sortup'
        },
        {
          icon: "el-icon-sort-down",
          name:'sort1'
        },
        {
          icon: "el-icon-rank",
          name:'rank'
        },
        {
          icon: "el-icon-loading",
          name:'loading'
        },
        {
          icon: "el-icon-view",
          name:'view'
        },
        {
          icon: "el-icon-c-scale-to-original",
          name:'scale'
        },
        {
          icon:  "el-icon-date",
          name:'date'
        },
        {
          icon:"el-icon-edit",
          name:'edit'
        },
        {
          icon:"el-icon-edit-outline",
          name:'edit1'
        },
        {
          icon:"el-icon-folder",
          name:'folder'
        },
       
        {
          icon:"el-icon-folder-opened",
          name:'folder2'
        },
        {
          icon:"el-icon-folder-add",
          name:'folder3'
        },
        {
          icon:"el-icon-folder-remove",
          name:'folder4'
        },
        
        {
          icon: "el-icon-folder-delete",
          name:'folder5'
        },
        {
          icon: "el-icon-folder-checked",
          name:'folder6'
        },
        {
          icon: "el-icon-tickets",
          name:'tickets'
        },
        {
          icon: "el-icon-tickets",
          name:'tickets'
        },
        {
          icon:"el-icon-document-remove",
          name:'doc1'
        },
        {
          icon:"el-icon-document-delete",
          name:'doc2'
        },
        {
          icon:"el-icon-document-checked",
          name:'doc3'
        },
        {
          icon:"el-icon-document",
          name:'doc4'
        },
        {
          icon: "el-icon-document-add",
          name:'doc5'
        },
        {
          icon: "el-icon-printer",
          name:'printer'
        },
        {
          icon: "el-icon-paperclip",
          name:'clip'
        },
        {
          icon:"el-icon-takeaway-box",
          name:'box'
        },
        {
          icon:"el-icon-search",
          name:'search'
        },
        {
          icon: "el-icon-monitor",
          name:'monitor'
        },
        {
          icon: "el-icon-attract",
          name:'attract'
        },
        {
          icon: "el-icon-mobile",
          name:'mobile'
        },
        {
          icon:  "el-icon-scissors",
          name:'scissors'
        },
        {
          icon: "el-icon-umbrella",
          name:'umbrella'
        },
        {
          icon: "el-icon-headset",
          name:'headset'
        },
        {
          icon:"el-icon-brush",
          name:'brush'
        },
        {
          icon:"el-icon-mouse",
          name:'mouse'
        },
        {
          icon:"el-icon-coordinate",
          name:'coordinate'
        },
        {
          icon:"el-icon-magic-stick",
          name:'magicstick'
        },
        {
          icon:"el-icon-reading",
          name:'reading'
        },
        {
          icon:"el-icon-data-line",
          name:'dataline'
        },
        {
          icon:"el-icon-data-board",
          name:'databoard'
        },
        {
          icon:"el-icon-pie-chart",
          name:'piechart'
        },
        {
          icon: "el-icon-data-analysis",
          name:'dataanalysis'
        },
        {
          icon: "el-icon-collection-tag",
          name:'col-tag'
        },
        {
          icon: "el-icon-film",
          name:' film'
        },
        {
          icon: "el-icon-suitcase",
          name:'suitcase'
        },
        {
          icon: "el-icon-suitcase-1",
          name:'suitcase1'
        },
        {
          icon:  "el-icon-receiving",
          name:'receiving'
        },
        {
          icon:  "el-icon-collection",
          name:'collection'
        },
        {
          icon:  "el-icon-files",
          name:'files'
        },
        {
          icon: "el-icon-notebook-1",
          name:'notebook1'
        },
        {
          icon: "el-icon-notebook-2",
          name:'notebook2'
        },
        {
          icon: "el-icon-toilet-paper",
          name:'tpaper'
        },
        {
          icon: "el-icon-office-building",
          name:'office'
        },
        {
          icon:"el-icon-school",
          name:'school'
        },
        {
          icon:"el-icon-table-lamp",
          name:'lamp'
        },  
        {
          icon: "el-icon-house",
          name:'house'
        },
        {
          icon: "el-icon-house",
          name:'house'
        },
        {
          icon:  "el-icon-no-smoking",
          name:'nosmoking'
        },
        {
          icon:   "el-icon-smoking",
          name:'smoking'
        },
        {
          icon:  "el-icon-shopping-cart-full",
          name:'cart'
        },
        {
          icon:  "el-icon-shopping-cart-1",
          name:'cart1'
        },
        {
          icon:  "el-icon-shopping-cart-2",
          name:'cart2'
        },
        {
          icon:  "el-icon-shopping-bag-1",
          name:'bag'
        },
        {
          icon:  "el-icon-shopping-bag-2",
          name:'bag2'
        },
        {
          icon:  "el-icon-sold-out",
          name:'sold'
        },
        {
          icon:  "el-icon-sell",
          name:'sell'
        },
        {
          icon:   "el-icon-present",
          name:'present'
        },
        {
          icon:   "el-icon-box",
          name:'box'
        },
        {
          icon:   "el-icon-bank-card",
          name:'card'
        },
        {
          icon:  "el-icon-money",
          name:'money'
        },
        {
          icon: "el-icon-coin",
          name:'coin'
        },
        {
          icon: "el-icon-wallet",
          name:'wallet'
        },
        {
          icon:  "el-icon-discount",
          name:'discount'
        },
        {
          icon: "el-icon-price-tag",
          name:'pricetag'
        },
        {
          icon: "el-icon-news",
          name:'news'
        },
        {
          icon: "el-icon-guide",
          name:'guide'
        },
        {
          icon: "el-icon-male",
          name:'male'
        },
        {
          icon: "el-icon-female",
          name:'female'
        },
        {
          icon: "el-icon-thumb",
          name:'thumb'
        },
        {
          icon: "el-icon-cpu",
          name:'cpu'
        },
        {
          icon: "el-icon-link",
          name:'link'
        },
        {
          icon:  "el-icon-connection",
          name:'connection'
        },
        {
          icon:  "el-icon-open",
          name:'open'
        },
        {
          icon:  "el-icon-turn-off",
          name:'turnoff'
        },
        {
          icon:  "el-icon-set-up",
          name:'set-up'
        },
        {
          icon:  "el-icon-chat-round",
          name:'chat1'
        },
        {
          icon:  "el-icon-chat-line-round",
          name:'chat2'
        },
        {
          icon:  "el-icon-chat-square",
          name:'chat3'
        },
        {
          icon:   "el-icon-chat-dot-round",
          name:'chat4'
        },
        {
          icon:   "el-icon-chat-dot-round",
          name:'chat5'
        },
        {
          icon:   "el-icon-chat-dot-square",
          name:'chat6'
        },
        {
          icon:   "el-icon-chat-line-square",
          name:'chat7'
        },
        {
          icon:   "el-icon-message",
          name:'message'
        },
        {
          icon:  "el-icon-postcard",
          name:'postcard'
        },
        {
          icon:  "el-icon-position",
          name:'position'
        },
        {
          icon:  "el-icon-turn-off-microphone",
          name:'off-mp'
        },
        {
          icon:  "el-icon-microphone",
          name:'mp'
        },
        {
          icon:   "el-icon-close-notification",
          name:'notification'
        },
        {
          icon:  "el-icon-bangzhu",
          name:' bangzhu'
        },
        {
          icon:   "el-icon-time",
          name:'time'
        },
        {
          icon:   "el-icon-odometer",
          name:'odometer'
        },
        {
          icon:   "el-icon-crop",
          name:'crop'
        },
        {
          icon:  "el-icon-aim",
          name:'aim'
        },
        {
          icon:  "el-icon-switch-button",
          name:'switch'
        },
        {
          icon:  "el-icon-full-screen",
          name:'switch'
        },
        {
          icon:  "el-icon-copy-document",
          name:'copy-doc'
        },
        {
          icon:   "el-icon-mic",
          name:'mic'
        },
        {
          icon:   "el-icon-stopwatch",
          name:'stopwatch'
        },
        {
          icon:    "el-icon-medal-1",
          name:'medal1'
        },
        {
          icon:    "el-icon-medal",
          name:'medal'
        },
        {
          icon:   "el-icon-trophy",
          name:'trophy'
        },
        {
          icon:  "el-icon-trophy-1",
          name:'trophy1'
        },
        {
          icon: "el-icon-first-aid-kit",
          name:'firstaid'
        },
        {
          icon: "el-icon-discover",
          name:'discover'
        },
        {
          icon: "el-icon-place",
          name:'place'
        },
        {
          icon: "el-icon-location",
          name:'location'
        },
        {
          icon: "el-icon-location-outline",
          name:'location2'
        },
        {
          icon:"el-icon-location-information",
          name:'info'
        },
        {
          icon:"el-icon-delete-location",
          name:'del-loc'
        },
        {
          icon:"el-icon-map-location",
          name:'map-loc'
        },
        {
          icon: "el-icon-alarm-clock",
          name:'alarmclock'
        },
        {
          icon: "el-icon-timer",
          name:'timer'
        },
        {
          icon:   "el-icon-watch-1",
          name:'watch1'
        },
        {
          icon:    "el-icon-watch",
          name:'watch'
        },
        {
          icon:    "el-icon-lock",
          name:'lock'
        },
        {
          icon:    "el-icon-unlock",
          name:'unlock'
        },
        {
          icon: "el-icon-key",
          name:'key'
        },
        {
          icon:  "el-icon-service",
          name:'service'
        },
        {
          icon:   "el-icon-mobile-phone",
          name:'mobile'
        },
        {
          icon:     "el-icon-bicycle",
          name:'bicycle'
        },
        {
          icon: "el-icon-truck",
          name:'truck'
        },
        {
          icon:  "el-icon-ship",
          name:'ship'
        },
        {
          icon:  "el-icon-basketball",
          name:'basketball'
        },
        {
          icon:  "el-icon-football",
          name:'football'
        },
        {
          icon:  "el-icon-soccer",
          name:'soccer'
        },
        {
          icon: "el-icon-baseball",
          name:'baseball'
        },
        {
          icon: "el-icon-wind-power",
          name:'windpower'
        },
        {
          icon: "el-icon-light-rain",
          name:'rain'
        },
        {
          icon: "el-icon-lightning",
          name:'lightning'
        },
        {
          icon:   "el-icon-heavy-rain",
          name:'rain2'
        },
        {
          icon:   "el-icon-sunrise",
          name:'sunrise'
        },
        {
          icon:   "el-icon-sunrise-1",
          name:'sunrise1'
        },
        {
          icon:   "el-icon-sunset",
          name:'sunset'
        },
        {
          icon:  "el-icon-sunny",
          name:'sunny'
        },
        {
          icon:  "el-icon-cloudy",
          name:'cloudy'
        },
        {
          icon: "el-icon-partly-cloudy",
          name:'cloudy2'
        },
        {
          icon: "el-icon-cloudy-and-sunny",
          name:'cloudy3'
        },
        {
          icon: "el-icon-moon",
          name:'moon'
        }, 
        {
          icon: "el-icon-moon-night",
          name:'moon2'
        },
        {
          icon: "el-icon-dish",
          name:'dish'
        },
        {
          icon: "el-icon-dish-1",
          name:'dish1'
        },
        {
          icon: "el-icon-food",
          name:'food'
        },
        {
          icon: "el-icon-chicken",
          name:'chicken'
        },
        {
          icon: "el-icon-fork-spoon",
          name:'fork'
        },
        {
          icon:  "el-icon-knife-fork",
          name:'knife'
        },
        {
          icon:  "el-icon-burger",
          name:'burger'
        },
        {
          icon:  "el-icon-tableware",
          name:'tableware'
        },
        {
          icon:  "el-icon-sugar",
          name:'sugar'
        },
        {
          icon: "el-icon-dessert",
          name:'dessert'
        },
        {
          icon:  "el-icon-ice-cream",
          name:'icecream'
        },
        {
          icon: "el-icon-hot-water",
          name:'hotwater'
        },
        {
          icon: "el-icon-water-cup",
          name:'cup'
        },
        {
          icon: "el-icon-coffee-cup",
          name:'coffee'
        },
        {
          icon:  "el-icon-cold-drink",
          name:'drink'
        },
        {
          icon: "el-icon-goblet",
          name:'goblet'
        },
        {
          icon: "el-icon-goblet-full",
          name:'goblet2'
        },
        {
          icon: "el-icon-goblet-square",
          name:'goblet3'
        },
        {
          icon: "el-icon-goblet-square",
          name:'goblet3'
        },
        {
          icon:"el-icon-goblet-square-full",
          name:'goblet4'
        },
        {
          icon:"el-icon-refrigerator",
          name:'refrigerator'
        },
        {
          icon:"el-icon-grape",
          name:'grape'
        },
        {
          icon:"el-icon-watermelon",
          name:'watermelon'
        },
        {
          icon:"el-icon-cherry",
          name:'cherry'
        },
        {
          icon:"el-icon-apple",
          name:'apple'
        },
        {
          icon:"el-icon-pear",
          name:'pear'
        }, 
        {
          icon:"el-icon-orange",
          name:'orange'
        }, 
        {
          icon:"el-icon-coffee",
          name:'coffee'
        },
        {
          icon:"el-icon-ice-tea",
          name:'icetea'
        },
        {
          icon:"el-icon-ice-drink",
          name:'icedrink'
        },
        {
          icon:"el-icon-milk-tea",
          name:'milktea'
        },
        {
          icon:"el-icon-potato-strips",
          name:'strips'
        },
        {
          icon:"el-icon-lollipop",
          name:'lollipop'
        },
        {
          icon:"el-icon-ice-cream-square",
          name:'icecream2'
        },
        {
          icon: "el-icon-ice-cream-round",
          name:'icecream3'
        },
        
       
      ],
    };
  },
  methods: {
    handleIconClick(iconClass) {
      bus.$emit("onSelectedIcon",iconClass)
      // Do something with the clicked icon class
      console.log(`Clicked icon class: ${iconClass}`);
  },
  searchIconName(event) {
      if (this.searchIcon) {
        this.iconsList = this.iconsArray.filter((el) =>
          el.name.includes(event)
        );
      }
    },
}
}
</script>

<style >
.icon-sty{
     /* display: flex !important; */
  display: inline-grid;
  grid-template-columns: auto  auto auto auto  auto auto auto auto ;
  font-size: 24px;
  cursor: pointer;
  /* padding:80px; */
  }
.paragraphScroll {
  height: 250px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
  width:100%
}
.paragraphScroll::-webkit-scrollbar {
  width: 0.7em;
}
.paragraphScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
i:hover {
    /* background-color: #dbf3fa; */
  }
  i:hover  {
    color: rgb(114, 212, 230);
 
  transform: translate(-10%, -10%);
}
/* tharuni end */
</style>